import * as React from "react";
import {
  CallToAction,
  Layout,
  MainBackground,
  ServicesMenuSection,
  SpecialHeader,
} from "../components/containers";
import { Product_Routes } from "../data/Routes";

import AboutImage from "../images/Aboutus.jpeg";

import Free from "../svgs/company/Free.svg";
import CustomQuote from "../svgs/company/CustomQuote.svg";
import Time from "../svgs/company/Time.svg";
import Climb from "../svgs/company/Climb.svg";
import { Button } from "../components/utility";
import Seo from "../components/marketing/Seo";
import { About_Meta } from "../data/MetaData";

const Aboutus = () => {
  return (
    <Layout>
      <Seo title={About_Meta.title} description={About_Meta.description} />
      <SpecialHeader
        src={AboutImage}
        title="About us"
        to={Product_Routes.Contactus}
      />

      <MainBackground>
        <section className="about-content">
          <div className="about-wrapper">
            <div className="about-title-container">
              <h1>Why Digital Comparison?</h1>
              <p>
                The Digital Comparison mission is to connect businesses with
                premium technical solutions. By matching you with leading
                suppliers of top-quality products, we help your company to
                thrive.
                <br />
                With years of experience in technical solutions behind us, we
                know exactly how to help you find the right products for your
                business. We use our network of qualified providers and trusted
                brands to bring you the best offers to meet your needs. Every
                offer is personalised and obligation free. Save time, stress,
                and money by letting Digital Comparison be the middle man.
              </p>
            </div>
            <div className="points-container">
              <div className="point-card">
                <img src={Free} alt="Free Service" />
                <h2>Totally Free Service</h2>
              </div>
              <div className="point-card">
                <img src={CustomQuote} alt="Custom Quotes" />
                <h2>Custom Quotes</h2>
              </div>
              <div className="point-card">
                <img src={Time} alt="Free Service" />
                <h2>Save time and stress</h2>
              </div>
              <div className="point-card">
                <img src={Climb} alt="Free Service" />
                <h2>Years of experience</h2>
              </div>
            </div>
            <Button to={Product_Routes.Contactus}>Get in touch with us</Button>
          </div>
        </section>
        <ServicesMenuSection />
        <CallToAction />
      </MainBackground>
    </Layout>
  );
};

export default Aboutus;
